import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { IconDocument, IconEnvelope, IconPhone, IconPhoto, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/link/code",
  "title": "Link - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "inline",
      "style": {
        "position": "relative"
      }
    }}>{`Inline`}<a parentName="h4" {...{
        "href": "#inline",
        "aria-label": "inline permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<p style={{ fontSize: '14px' }}>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
  aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
  <Link
    href="https://github.com/City-of-Helsinki/helsinki-design-system"
    external
    openInExternalDomainAriaLabel="Opens a different website"
  >
    HDS Github
  </Link>
  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</p>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<p style={{ fontSize: '14px', lineHeight: '1.5' }}>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  <a href="https://github.com/City-of-Helsinki/helsinki-design-system" class="hds-link hds-link--small" aria-label="HDS Github. Opens in external domain">
  HDS Github<span class="hds-icon icon hds-icon--link-external hds-icon--size-xs vertical-align-small-or-medium-icon" aria-hidden="true"></span></a>
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</p>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "standalone",
      "style": {
        "position": "relative"
      }
    }}>{`Standalone`}<a parentName="h3" {...{
        "href": "#standalone",
        "aria-label": "standalone permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Link href="https://hds.hel.fi/" size="M" style={{ display: 'block', width: 'fit-content' }}>
  Link to HDS
</Link>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<a href="https://hds.hel.fi/" class="hds-link hds-link--medium" style="display:block;">Standalone link</a>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "internal-and-external-links",
      "style": {
        "position": "relative"
      }
    }}>{`Internal and external links`}<a parentName="h3" {...{
        "href": "#internal-and-external-links",
        "aria-label": "internal and external links permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
  <Link href="https://hds.hel.fi/" size="M" style={{ display: 'block', width: 'fit-content' }}>
    Internal link
  </Link>

  <Link
    href="https://github.com/City-of-Helsinki/helsinki-design-system"
    size="M"
    external
    openInExternalDomainAriaLabel="Opens a different website."
    style={{ display: 'block', width: 'fit-content' }}
  >
    External link
  </Link>

  <Link
    href="https://github.com/City-of-Helsinki/helsinki-design-system"
    size="M"
    external
    openInNewTab
    openInExternalDomainAriaLabel="Opens a different website."
    openInNewTabAriaLabel="Opens in a new tab."
    style={{ display: 'block', width: 'fit-content' }}
  >
    Link that opens in a new tab
  </Link>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<p>
  <a href="https://hds.hel.fi/" class="hds-link hds-link--medium" style="display:block;">Internal link</a>

  <a
    href="https://github.com/City-of-Helsinki/helsinki-design-system"
    class="hds-link hds-link--medium"
    aria-label="Opens a different website."
  >
    External link
    <span
      class="hds-icon icon hds-icon--link-external hds-icon--size-s vertical-align-small-or-medium-icon"
      aria-hidden="true"
    ></span>
  </a>

  <a
    href="https://github.com/City-of-Helsinki/helsinki-design-system"
    class="hds-link hds-link--medium"
    rel="noopener"
    target="_blank"
    aria-label="Opens a different website. Opens in a new tab."
  >
    Link that opens in a new tab
    <span
      class="hds-icon icon hds-icon--link-external hds-icon--size-xs vertical-align-small-or-medium-icon"
      aria-hidden="true"
    ></span>
  </a>
</p>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "links-with-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Links with icons`}<a parentName="h3" {...{
        "href": "#links-with-icons",
        "aria-label": "links with icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
  <Link
    iconLeft={<IconDocument size="s" aria-hidden />}
    size="M"
    href="/#"
    style={{ display: 'block', width: 'fit-content' }}
  >
    Document link
  </Link>
  <Link
    iconLeft={<IconPhone size="s" aria-hidden />}
    size="M"
    href="/#"
    style={{ display: 'block', width: 'fit-content' }}
  >
    Phone link
  </Link>
  <Link
    iconLeft={<IconEnvelope size="s" aria-hidden />}
    size="M"
    href="/#"
    style={{ display: 'block', width: 'fit-content' }}
  >
    Email link
  </Link>
  <Link
    iconLeft={<IconPhoto size="s" aria-hidden />}
    size="M"
    href="/#"
    style={{ display: 'block', width: 'fit-content' }}
  >
    Photo link
  </Link>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<p>
  <a href="/#" class="hds-link hds-link--medium">
    <span class="hds-icon-left"><span class="hds-icon hds-icon--document hds-icon--size-s" aria-hidden="true"></span></span>
    Document link
  </a>

  <a href="/#" class="hds-link hds-link--medium">
    <span class="hds-icon-left"><span class="hds-icon hds-icon--phone hds-icon--size-s" aria-hidden="true"></span></span>
    Phone link
  </a>

  <a href="/#" class="hds-link hds-link--medium">
    <span class="hds-icon-left"><spani class="hds-icon hds-icon--envelope hds-icon--size-s" aria-hidden="true"></span></span>
    Envelope link
  </a>

  <a href="/#" class="hds-link hds-link--medium">
    <span class="hds-icon-left"><span class="hds-icon hds-icon--photo hds-icon--size-s" aria-hidden="true"></span></span>
    Photo link
  </a>
</p>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-link--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/link" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-link--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/link" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-link--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`external`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the link is marked as external (points outside of the domain).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInExternalDomainAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label which is read if the link is external.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An icon placed on the left side of the link.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInNewTab`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the link opens in a new tab.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInNewTabAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label which is read if the link is opened in a new tab.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the size of the link.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"S"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"M"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"L"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"S"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hypertext reference of the link.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      